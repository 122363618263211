import JshModule from '@core/scripts/helper/jsh-module';

function skipToNextSection(skipButton) {
    skipButton.addEventListener("click", function() {
        let nextSection = skipButton.closest('article')?.nextElementSibling;

        while (nextSection && !['ARTICLE', 'DIV', 'SECTION'].includes(nextSection.tagName)) {
            nextSection = nextSection.nextElementSibling;
        }

        if (nextSection) {
            nextSection.setAttribute('tabindex', '-1');
            nextSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            nextSection.focus();
        }
    });
}

do {
    const {moduleScope, config} = JshModule('teaser/skipToNextSection');
    if (moduleScope !== null) {
        skipToNextSection(moduleScope)
    }

} while (document.querySelector('[jsh-module="teaser/skipToNextSection"]') !== null);